import React from 'react'

import QR1 from '@assets/qrcode1.webp'
import QR2 from '@assets/qrcode2.webp'

export default () => (
    <div className="card donate">
        <img src={QR1} alt="" className="qrcode" />
        <img src={QR2} alt="" className="qrcode" />
    </div>
)