import React from 'react'

import JF from '@assets/jellyfin.png'
import QB from '@assets/qb.png'
import NAV from '@assets/nav.png'
import WOT from '@assets/wot.webp'
import NOTE from '@assets/note.png'
import RCC from '@assets/rcc.png'

export default () => {
    return (
        <div className="lab-list">
            <a className="lab-item" href="https://v.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={JF} />
                </div>
                <div className="lab-text">
                    <div className="name">Jellyfin</div>
                    <div className="desc">v.src.moe</div>
                    <div className="desc">视频站</div>
                    <div className="tags">
                        <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Homelab-blue" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://qb.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={QB} />
                </div>
                <div className="lab-text">
                    <div className="name">qBittorrent</div>
                    <div className="desc">qb.src.moe</div>
                    <div className="desc">远程下载站</div>
                    <div className="tags">
                        <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Homelab-blue" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://nav.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={NAV} />
                </div>
                <div className="lab-text">
                    <div className="name">导航</div>
                    <div className="desc">nav.src.moe</div>
                    <div className="desc">为电视屏幕设计的导航站</div>
                    <div className="tags">
                    <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Vercel-171717" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://wot.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={WOT} />
                </div>
                <div className="lab-text">
                    <div className="name">坦克世界数据分析</div>
                    <div className="desc">wot.src.moe</div>
                    <div className="desc">每日抓取360国服数据</div>
                    <div className="tags">
                        <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Homelab-blue" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://note.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={NOTE} />
                </div>
                <div className="lab-text">
                    <div className="name">云记事本</div>
                    <div className="desc">note.src.moe</div>
                    <div className="desc">基于Cloudflare Workers的云记事本</div>
                    <div className="tags">
                        <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Cloudflare-orange" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://memos.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src="https://memos.src.moe/logo.svg" />
                </div>
                <div className="lab-text">
                    <div className="name">记录仓库</div>
                    <div className="desc">memos.src.moe</div>
                    <div className="desc">自建的笔记 / 知识碎片应用</div>
                    <div className="tags">
                        <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Homelab-blue" alt="" />
                    </div>
                </div>
            </a>
            <a className="lab-item" href="https://dance.src.moe" target="_blank">
                <div className="lab-icon">
                    <img className="icon-img" src={RCC} />
                </div>
                <div className="lab-text">
                    <div className="name">Roast Chicken Collection</div>
                    <div className="desc">dance.src.moe</div>
                    <div className="desc">We love dance~</div>
                    <div className="tags">
                    <img src="https://img.shields.io/badge/%E4%BD%8D%E4%BA%8E-Homelab-blue" alt="" />
                    </div>
                </div>
            </a>
        </div>
    )
}