import React from 'react'
import useSWR from 'swr'
import formatBytes from 'superbytes'
import humanizeDuration from 'humanize-duration'
import isMobile from 'is-mobile'

const TORR_STATE_MAP = {
    downloading: '91,185,116',
    complete: '153,153,153',
    warning: '211,47,47',
}

const DEVICE_NAME_MAP = {
    '/dev/sda1': 'HDD2',
    '/dev/sdb1': 'HDD1',
    '/dev/nvme0n1p4': 'SSD',
}

const SENSORS_MAP = {
    'HDD1': 'sdb',
    'HDD2': 'sda',
    'SSD': 'Composite',
}

const formatDuration = sec => {
    if (sec >= 8640000) return '∞'
    const time = humanizeDuration.humanizer({
        language: 'zh_CN',
        largest: 2,
        spacer: '',
    })(sec * 1000)
    return `剩余${time}`
}
const fetcher = (...args) => fetch(...args).then((res) => res.json())
const goURL = url => () => window.open(url, '_blank')

function ProgressBar(props) {
    const {
        per = 0,
        lt,
        rt,
        bgc = '#EAEEF7',
        color,
        lc = '#fff',
        lgc = '#999',
        rc = '#999',
    } = props
    const barColor = color || (+per > 90 ? '#E23636' : +per > 65 ? '#FFCC00' : '#5EC341')
    return (
        <svg className="progress-bar" viewBox="0 0 100 6">
            <rect width="100" height="6" rx="3" ry="3" fill={bgc}></rect>
            {
                !!lt && <text x="3" y="5" fill={lgc} style={{ fontSize: 5 }}>{lt}</text>
            }
            <rect className="curr" width={per} height="6" rx="3" ry="3" fill={barColor}></rect>
            {
                !!lt && (
                    <svg width={per} height="6" >
                        <text x="3" y="5" fill={lc} style={{ fontSize: 5 }}>{lt}</text>
                    </svg>
                )
            }
            {
                !!rt && <text x="97" y="5" fill={rc} textAnchor="end" style={{ fontSize: 6 }}>{rt}</text>
            }
        </svg>
    )
}

function DevStatus({ devices }) {
    if (!devices) return null
    
    const {
        core,
        system,
        load,
        uptime,
        cpu,
        fs,
        mem,
        containers = [],
        sensors = [],
    } = devices

    const hddList = fs
        .filter(v => v.mnt_point.includes('/rootfs/mnt') || v.mnt_point === '/rootfs')
        .map(v => ({ ...v, id: DEVICE_NAME_MAP[v.device_name] }))
    const dockerRunNum = containers.filter(v => v.status === 'running').length
    const dockerErrNum = containers.length - dockerRunNum
    return (
        <>
            <div className="panel-title">
                <span className="panel-title-text">设备：{system.hostname || '--'}</span>
                <span className="ant-click ant-tag ant-tag-blue" onClick={goURL('//glances.src.moe:8000')}>详情</span>
            </div>
            <div className="device-info">
                <div className="device-info-item">
                    <span className="iname">状态:</span>
                    <span className="ant-tag ant-tag-orange">已运行 {uptime || '--'}</span>
                </div>
                <div className="device-info-item">
                    <span className="iname">系统:</span>
                    <span className="ant-tag ant-tag-blue">{system.linux_distro || '--'}</span>
                    <span className="ant-tag ant-tag-blue">{system.os_version || '--'}</span>
                </div>
                <div className="device-info-item">
                    <span className="iname">CPU:</span>
                    <ProgressBar
                        per={cpu.total}
                        lt={`${core.phys}核心${core.log}线程`}
                        rt={`${cpu.total || 0} %`}
                    />
                    <span className="ml8 ant-tag ant-tag-blue">{`${sensors.find(v => v.label === 'Tctl')?.value} ℃`}</span>
                </div>
                <div className="device-info-item">
                    <span className="iname">内存:</span>
                    <ProgressBar
                        per={mem.percent}
                        lt={`${mem.percent || 0} %`}
                        rt={`${formatBytes(mem.used, 1)} / ${formatBytes(mem.total, 1)}`}
                    />
                </div>
                {
                    hddList.map((disk, idx) => {
                        const { value: temp } = sensors.find(v => SENSORS_MAP[disk.id] === v.label)
                        const t = typeof temp === 'number'
                            ? `${temp} ℃`
                            : temp === 'SLP'
                                ? '休眠中'
                                : temp
                        return (
                            <div className="device-info-item" key={idx}>
                                <span className="iname">{idx === 0 ? '硬盘:' : ''}</span>
                                <ProgressBar
                                    per={disk.percent}
                                    lt={disk.id}
                                    rt={`${formatBytes(disk.used, 1)} / ${formatBytes(disk.size, 1)}`}
                                />
                                <span className="ml8 ant-tag ant-tag-blue">{t}</span>
                            </div>
                        )
                    })
                }
                <div className="device-info-item">
                    <span className="iname">负载:</span>
                    <span className="ant-tag ant-tag-blue-re">{load.min1.toFixed(2) || 0}</span>
                    <span className="ant-tag ant-tag-blue-re">{load.min5.toFixed(2) || 0}</span>
                    <span className="ant-tag ant-tag-blue-re">{load.min15.toFixed(2) || 0}</span>
                </div>
                <div className="device-info-item">
                    <span className="iname">容器:</span>
                    <span className="ant-click ant-tag ant-tag-green ant-tag-icon" onClick={goURL('//portainer.src.moe:8000')}>
                        <svg className="anticon-spin" viewBox="64 64 896 896" focusable="false" data-icon="sync" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M168 504.2c1-43.7 10-86.1 26.9-126 17.3-41 42.1-77.7 73.7-109.4S337 212.3 378 195c42.4-17.9 87.4-27 133.9-27s91.5 9.1 133.8 27A341.5 341.5 0 01755 268.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.7 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c0-6.7-7.7-10.5-12.9-6.3l-56.4 44.1C765.8 155.1 646.2 92 511.8 92 282.7 92 96.3 275.6 92 503.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8zm756 7.8h-60c-4.4 0-7.9 3.5-8 7.8-1 43.7-10 86.1-26.9 126-17.3 41-42.1 77.8-73.7 109.4A342.45 342.45 0 01512.1 856a342.24 342.24 0 01-243.2-100.8c-9.9-9.9-19.2-20.4-27.8-31.4l60.2-47a8 8 0 00-3-14.1l-175.7-43c-5-1.2-9.9 2.6-9.9 7.7l-.7 181c0 6.7 7.7 10.5 12.9 6.3l56.4-44.1C258.2 868.9 377.8 932 512.2 932c229.2 0 415.5-183.7 419.8-411.8a8 8 0 00-8-8.2z"></path></svg>
                        运行中 {dockerRunNum}
                    </span>
                    {
                        !!dockerErrNum && (
                            <span className="ant-click ant-tag ant-tag-red ant-tag-icon" onClick={goURL('//portainer.src.moe:8000')}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                                异常 {dockerErrNum}
                            </span>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default () => {
    const { data: devices } = useSWR('//glances.src.moe:8000/api/4/all', fetcher, { refreshInterval: 2000 })
    const { data: torrentList = [] } = useSWR('//qbit-status.src.moe', fetcher, { refreshInterval: 5000 })

    return (
        <div className="dashboard">
            <div className="panel device">
                <DevStatus devices={devices} />
            </div>
            <div className="panel torrent">
                <div className="panel-title">
                    <span className="panel-title-text">下载任务</span>
                    <span className="ant-click ant-tag ant-tag-blue" onClick={goURL(isMobile() ? '//qb.src.moe' : '//qbit.src.moe:8000')}>详情</span>
                </div>
                {
                    !torrentList.length && <div className="empty-holder">暂无任务</div>
                }
                <table className="torrents-list">
                    <tbody>
                        {
                            torrentList.map(torr => {
                                const eta = formatDuration(torr.eta)
                                const color = torr.amount_left === 0 ? TORR_STATE_MAP.complete : (TORR_STATE_MAP[torr.state] || TORR_STATE_MAP.warning)
                                return (
                                    <tr key={torr.hash}>
                                        <td className="td-title" title={torr.name}>{torr.name}</td>
                                        <td className="td-progress">
                                            <ProgressBar
                                                color={`rgb(${color})`}
                                                bgc={`rgba(${color},.3)`}
                                                lgc="#fff"
                                                rc="#fff"
                                                per={torr.progress * 100}
                                                lt={`${(torr.progress * 100).toFixed(1)} %`}
                                                rt={`${formatBytes(torr.downloaded, 2)} / ${formatBytes(torr.size, 2)}`}
                                            />
                                        </td>
                                        <td className="td-speed">
                                            {formatBytes(torr.dlspeed, 1)}/s
                                        </td>
                                        <td className="td-eta" title={eta}>{eta}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}