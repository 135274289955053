import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Router } from '@reach/router'

import Home from './Home'
import NotFound from './NotFound'

const Root = () => (
  <Router>
    <Home path="/" />
    <Home path="/donate" />
    <NotFound default />
  </Router>
)

export default hot(Root);
