import React, { useState, useEffect, useRef } from 'react'
import Tabs, { TabPane } from 'rc-tabs'

import LabsPage from './components/labs'
import DashboardPage from './components/dashboard'
import DonatePage from './components/donate'
import AVATAR from '@assets/avatar.jpg'
import './index.scss'

function useTimeout(func, delay) {
    const ref = useRef(null)

    useEffect(() => {
        ref.current = func
    }, [func])

    useEffect(() => {
        if (delay === 0) return;

        const tid = setTimeout(() => {
            ref.current()
        }, delay)

        return () => {
            clearTimeout(tid)
        }
    }, [delay])
}

function Home(props) {
    const conRef = useRef(null)
    const canvasRef = useRef(null)
    const [activeTab, setTab] = useState(props.path === '/donate' ? 'donate' : 'labs')
    const [clickCount, setCount] = useState(0)
    const [delay, setDelay] = useState(0)

    useTimeout(() => {
        if (clickCount > 6) {
            window.localStorage['ACTIVE_ADMIN'] = 1
        }
        setCount(0)
        setDelay(0)
    }, delay)

    const clickAvatar = () => {
        setCount(c => c + 1)
        setDelay(2000)
    }

    const playBgAnimation = (width, height) => {
        const fps = 6;
        const resolution = 10;
        const COLS = width / resolution;
        const ROWS = height / resolution;

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        function nextGen(grid) {
            const nextGen = grid.map(arr => [...arr]);

            for (let col = 0; col < grid.length; col++) {
                for (let row = 0; row < grid[col].length; row++) {
                    const cell = grid[col][row];
                    let numNeighbours = 0;
                    for (let i = -1; i < 2; i++) {
                        for (let j = -1; j < 2; j++) {
                            if (i === 0 && j === 0) {
                                continue;
                            }
                            const x_cell = col + i;
                            const y_cell = row + j;

                            if (x_cell >= 0 && y_cell >= 0 && x_cell < COLS && y_cell < ROWS) {
                                const currentNeighbour = grid[col + i][row + j];
                                numNeighbours += currentNeighbour;
                            }
                        }
                    }

                    // rules
                    if (cell === 1 && numNeighbours < 2) {
                        nextGen[col][row] = 0;
                    } else if (cell === 1 && numNeighbours > 3) {
                        nextGen[col][row] = 0;
                    } else if (cell === 0 && numNeighbours === 3) {
                        nextGen[col][row] = 1;
                    }
                }
            }
            return nextGen;
        }

        function render(grid) {
            for (let col = 0; col < grid.length; col++) {
                for (let row = 0; row < grid[col].length; row++) {
                    const cell = grid[col][row];

                    ctx.beginPath();
                    ctx.rect(col * resolution, row * resolution, resolution, resolution);
                    ctx.fillStyle = cell ? '#eee' : '#f5f5fa';
                    ctx.fill();
                    // ctx.stroke();
                }
            }
        }

        function buildGrid() {
            return new Array(COLS).fill(null)
                .map(() => new Array(ROWS).fill(null)
                    .map(() => Math.floor(Math.random() * 2)));
        }

        let grid = buildGrid();

        requestAnimationFrame(update);

        function update() {
            grid = nextGen(grid);
            render(grid);
            // requestAnimationFrame(update);

            setTimeout(() => {
                requestAnimationFrame(update);
            }, 1000 / fps);
        }
    }

    useEffect(() => {
        if (conRef.current) {
            const { width, height } = conRef.current.getBoundingClientRect()
            const w = Math.ceil(width / 10) * 10
            const h = Math.ceil(height / 10) * 10
            playBgAnimation(w, h)
        }
    }, [])

    const isLock = !window.localStorage['ACTIVE_ADMIN']
    return (
        <div className="container" ref={conRef}>
            <img src={AVATAR} alt="avatar" className="main-avatar" onClick={clickAvatar} />
            <div className="card main">
                <div className="site-title">s0urce</div>
                <div className="site-links">
                    <a className="link-item" href="https://github.com/s0urcelab" title="Github">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
                        </svg>
                    </a>
                    <a className="link-item" href="mailto:me@src.moe" title="邮箱">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="4" />
                            <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                        </svg>
                    </a>
                    <a className="link-item" href="https://blog.src.moe" title="博客">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <line x1="3" y1="6" x2="3" y2="19" />
                            <line x1="12" y1="6" x2="12" y2="19" />
                            <line x1="21" y1="6" x2="21" y2="19" />
                        </svg>
                    </a>

                    <a className="link-item" href="https://t.me/s0urce1911" title="Telegram">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                        </svg>
                    </a>
                </div>
                <Tabs activeKey={activeTab} onChange={tab => setTab(tab)}>
                    <TabPane tab="🐁 实验室" key="labs">
                        <LabsPage />
                    </TabPane>
                    <TabPane tab="⚙ 仪表盘" key="dashboard" disabled={isLock} >
                        {
                            activeTab === 'dashboard' && <DashboardPage />
                        }
                    </TabPane>
                    <TabPane tab="♥ 音乐/电影/游戏" key="recommend" disabled >
                    </TabPane>
                    <TabPane tab="☕ 捐赠" key="donate">
                        <DonatePage />
                    </TabPane>
                </Tabs>
            </div>
            <canvas className="bg-canvas" ref={canvasRef}></canvas>
        </div>
    )
}

export default Home;
